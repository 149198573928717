import React from "react"
import "./styles.scss"

const OfficeMap = () => {
  return (
    <div className="contact-container">
      <h2>Visit Us</h2>
      <div id="map-container">
        <iframe loading="lazy" title="google-map" allowFullScreen src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ13gwLVYrDogR3pVMdJ1UF3k&key=AIzaSyAQiDMrG-gZMKEiLzN995K8jcw5vUI951Y" />
      </div>
      <address className={`text-right address`}>
        Typenex Medical, LLC
        <br />
        303 E. Wacker Drive, Suite 1030
        <br />
        Chicago, IL 60601
      </address>
    </div>
  )
}

export default OfficeMap
