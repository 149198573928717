import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import "./styles.scss"

const FormLabel = ({ label, validator, disabled, requiredMsg, children }) => {
  return (
    <label className="label">
      {label}&nbsp;
      {!disabled &&
        (validator() ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : (
          <span className="required">
            {requiredMsg ? (
              <label className="pl-2 small" data-cy="warning">
                {requiredMsg}
              </label>
            ) : (
              "*"
            )}
          </span>
        ))}
      {children}
    </label>
  )
}

export default FormLabel
